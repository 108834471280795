import React, { useState, useEffect } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  NavLink,
} from 'react-router-dom'
import { ethers } from 'ethers'

import {
  networks,
  defaultNetwork,
  setupNetwork,
  setProvider,
  getProvider,
  setSigner,
  getSigner,
  getNetwork,
  setNetwork,
  getToken,
} from './logic/web3'

import Wrap from './components/Wrap'
import Unwrap from './components/Unwrap'
import Delegate from './components/Delegate'
import Delegations from './components/Delegations'
import Claim from './components/Claim'
import Footer from './components/footer/Footer'
import Mobile from './components/mobile/Mobile'

import icon from './icon.svg'
import person from './person.svg'

import './App.css'

function App() {
  const [connectionState, setConnectionState] = useState({
    connected: false,
    msg: 'Connect Metamask',
  })
  async function connect() {
    console.log('Connecting...')
    try {
      // connect
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      await checkNetwork()
      await checkAccount()
      if (getNetwork() !== undefined) {
        const symbol = getNetwork().nativeCurrency.symbol
        const address = await getSigner().getAddress()
        const balance = await getSigner().getBalance()

        setConnectionState({ connected: true, symbol, address, balance })
      }
    } catch (err) {
      if (err.code === 4001) {
        // user rejected the connection
        console.log('Please connect to MetaMask.')
      } else {
        console.error(err)
        setConnectionState({ connected: false, msg: 'Connect Metamask' })
      }
    }
  }

  async function checkNetwork() {
    //window.ethereum.on('chainChanged', () => window.location.reload())
    let chainId = await window.ethereum.request({ method: 'eth_chainId' })
    if (networks[chainId] !== undefined) {
      setNetwork(networks[chainId])
      await handleChainChanged(chainId)
    } else if (window.ethereum.isMetaMask) {
      await setupNetwork(defaultNetwork)
      if (
        chainId === (await window.ethereum.request({ method: 'eth_chainId' }))
      )
        setConnectionState({ connected: false, msg: 'Please change network' })
    } else {
      setConnectionState({ connected: false, msg: 'Please change network' })
    }
  }

  async function handleChainChanged(chainId) {
    console.log('Chain changed to ' + chainId)
    await setupNetwork(chainId)

    if (
      chainId !== (await window.ethereum.request({ method: 'eth_chainId' }))
    ) {
      // user denied
      return
    }

    setNetwork(networks[chainId])
    // reload provider and signer on chain change
    setProvider(new ethers.providers.Web3Provider(window.ethereum))
    setSigner(getProvider().getSigner())

    if (getNetwork() !== undefined) {
      const symbol = getNetwork().nativeCurrency.symbol
      const address = await getSigner().getAddress()
      const balance = await getSigner().getBalance()

      setConnectionState({ connected: true, symbol, address, balance })
    } else {
      setConnectionState({ connected: false, msg: 'Please change network' })
    }
  }

  async function checkAccount() {
    let accounts = await window.ethereum.request({ method: 'eth_accounts' })
    window.ethereum.on('accountsChanged', () => window.location.reload())
    await handleAccountsChanged(accounts)
  }

  async function handleAccountsChanged(accounts) {
    console.log('Account changed to ' + accounts)

    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setConnectionState({ connected: false, msg: 'Connect Metamask' })
    } else {
      if (getNetwork() !== undefined) {
        const symbol = getNetwork().nativeCurrency.symbol
        const address = await getSigner().getAddress()
        const balance = await getSigner().getBalance()

        setConnectionState({ connected: true, symbol, address, balance })
      }
    }
  }

  useEffect(() => {
    async function checkWeb3() {
      if (typeof window.ethereum !== 'undefined') {
        console.log('Web3 provider is installed!')
        setProvider(new ethers.providers.Web3Provider(window.ethereum))
        setSigner(getProvider().getSigner())
        try {
          // check if already connected by getting the wallet address
          await getSigner().getAddress()
          await checkNetwork()
          await checkAccount()
          if (getNetwork() !== undefined) {
            const symbol = getNetwork().nativeCurrency.symbol
            const address = await getSigner().getAddress()
            const balance = await getSigner().getBalance()

            setConnectionState({ connected: true, symbol, address, balance })
          }
        } catch (err) {
          // if not already connected, show the connect button
          console.log(err)
          setConnectionState({ connected: false, msg: 'Connect Metamask' })
        }
      } else {
        console.log('Please install MetaMask!')
        setConnectionState({
          connected: false,
          msg: 'Please install MetaMask!',
        })
      }
    }
    checkWeb3()

    // eslint-disable-next-line
  }, [])

  return window.innerWidth > 800 ? (
    <div className='app'>
      <Router>
        <header className='header' id='header'>
          <div className='header__container'>
            <div className='header__nav'>
              <a
                href='https://ftso.scandinodes.com'
                target='_blank'
                rel='noreferrer'
              >
                <img src={icon} alt='Scandinodes' className='heaeder__icon' />
              </a>
              <NavLink
                to='/wrap'
                className='header__link'
                activeClassName='header__link__active'
              >
                Wrap
              </NavLink>
              <NavLink
                to='/unwrap'
                className='header__link'
                activeClassName='header__link__active'
              >
                Unwrap
              </NavLink>
              <NavLink
                to='/delegate'
                className='header__link'
                activeClassName='header__link__active'
              >
                Delegate
              </NavLink>
              <NavLink
                to='/delegations'
                className='header__link'
                activeClassName='header__link__active'
              >
                Your delegations
              </NavLink>
            </div>
            <div className='header__nav'>
              {connectionState.connected && getNetwork() && (
                <button
                  className='header__button__dark'
                  onClick={async () =>
                    await handleChainChanged(
                      getNetwork().chainId === '0xe' ? '0x13' : '0xe',
                    )
                  }
                >
                  {getNetwork().chainName}
                </button>
              )}
              <button
                className='header__button'
                disabled={connectionState.connected}
                onClick={connect}
              >
                {connectionState.connected
                  ? connectionState.address.substring(0, 6) +
                    '...' +
                    connectionState.address.substring(38) +
                    ' | ' +
                    (
                      connectionState.balance / 1_000_000_000_000_000_000
                    ).toFixed(3) +
                    ' ' +
                    connectionState.symbol
                  : connectionState.msg}
              </button>
              <img
                className='disconnect__button'
                src={person}
                alt='disconnect'
                onClick={() => {
                  window.ethereum.request({
                    method: 'wallet_requestPermissions',
                    params: [{ eth_accounts: {} }],
                  })
                }}
              />
            </div>
          </div>
        </header>
        <section className='body'>
          <div className='body__container'>
            <div className='body__wrap'>
              {connectionState.connected ? (
                <Switch>
                  <Route path='/wrap' component={Wrap} />
                  <Route path='/unwrap' component={Unwrap} />
                  <Route path='/delegate' component={Delegate} />
                  <Route path='/delegations' component={Delegations} />
                  <Route path='/claim' component={Claim} />
                  <Route>
                    <Redirect to='/wrap' />
                  </Route>
                </Switch>
              ) : (
                <>
                  <h1 className='body__title'>ScandiNodes FTSO App</h1>
                  <p className='body__login__txt'>
                    ScandiNodes FTSO dapp helps you delegate {getToken()}{' '}
                    tokens. Read more about songbird{' '}
                    <a
                      href='https://flare.network/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      here
                    </a>
                    . We aim to give you a excellent service with highest
                    possible yield.
                  </p>
                  <p className='body__login__txt'>
                    Thank you for choosing{' '}
                    <a
                      href='https://ftso.scandinodes.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Scandinodes
                    </a>
                    . Make sure to{' '}
                    <a
                      href='https://twitter.com/scandinodesFTSO'
                      target='_blank'
                      rel='noreferrer'
                    >
                      follow us on Twitter
                    </a>{' '}
                    to get the latest information.
                  </p>
                  <button
                    className='header__button'
                    disabled={connectionState.connected}
                    onClick={connect}
                    style={{
                      margin: '0 auto',
                      display: 'block',
                      marginTop: '2rem',
                    }}
                  >
                    {connectionState.msg}
                  </button>
                </>
              )}
            </div>
          </div>
          <Footer />
        </section>
      </Router>
    </div>
  ) : (
    <Mobile />
  )
}

export default App
