import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import {
  getWnatBalance,
  delegate,
  getAvailableDelegations,
  getScandiNodesDelegations,
  getToken,
} from './../logic/web3'

import loader from '../loader.svg'

function Delegate() {
  const [appState, setAppState] = useState({ loading: true, amount: 100 })
  const [delegation, setdelegation] = useState({
    balance: 0,
    available: 100,
    delegated: 0,
  })

  useEffect(() => {
    async function getAppState() {
      setAppState({ loading: true, amount: 100 })
      const balance = await getWnatBalance()
      const available = await getAvailableDelegations()
      const delegated = await getScandiNodesDelegations()
      setdelegation({ balance, available, delegated })
      setAppState({ loading: false, amount: available })
    }
    getAppState()
  }, [])

  async function delegateTx() {
    setAppState({ loading: true })
    const result = await delegate(appState.amount).catch((e) => {
      return e
    })
    setAppState({ loading: false, result })
  }

  return appState.loading ? (
    <>
      <h1 className='body__title'>Delegate</h1>
      <img src={loader} alt='loading' className='body__loader' />
      <p className='body__loader__txt'>loading...</p>
    </>
  ) : !appState.result ? (
    <>
      <h1 className='body__title'>Delegate</h1>
      {delegation.balance > 0 ? (
        <>
          <h2 className='body__delegation__percent'>{appState.amount}%</h2>
          <input
            type='range'
            id='delegate'
            name='delegate'
            min='0'
            max='100'
            step='1'
            defaultValue={appState.amount}
            style={{
              background: `linear-gradient(to right, var(--blue) 0%, var(--blue)  ${appState.amount}%, #AAC6ED ${appState.amount}%, #AAC6ED 100%)`,
            }}
            onChange={() =>
              setAppState({
                loading: false,
                amount: document.getElementById('delegate').value,
              })
            }
          />

          <p className='body__balance__txt'>
            Your balance: {delegation.balance} w{getToken()}
          </p>
          {appState.amount > delegation.available ? (
            <>
              <p className='body__balance__txt' style={{ marginTop: '2.6rem' }}>
                You already have delegations in others FTSO.
                <br />
                Want to delegate more to ScandiNodes?
              </p>
              <NavLink
                className='body__button'
                to='/delegations'
                style={{ marginTop: '1rem' }}
              >
                Undelegate from another FTSO
              </NavLink>
            </>
          ) : appState.amount <= delegation.delegated ? (
            <p className='body__balance__txt' style={{ marginTop: '2.6rem' }}>
              You are already delegating {delegation.delegated}% to ScandiNodes
            </p>
          ) : (
            <button
              className='body__button__completed'
              onClick={delegateTx}
              style={{ padding: '.3rem' }}
            >
              Delegate{' '}
              {(Math.round(delegation.balance * appState.amount) / 100).toFixed(
                2,
              )}{' '}
              w{getToken()} to ScandiNodes
            </button>
          )}
        </>
      ) : (
        <>
          <p className='body__balance__txt' style={{ paddingTop: '2rem' }}>
            You don't have enough <span>w{getToken()}</span>
          </p>
          <NavLink
            className='body__button__completed'
            to='/wrap'
            style={{ marginTop: '1rem' }}
          >
            Wrap your <span>{getToken()}</span>
          </NavLink>
        </>
      )}
    </>
  ) : appState.result.code ? (
    <>
      <h1 className='body__title'>Error</h1>
      <p className='body__result__txt'>
        {appState.result.data
          ? appState.result.data.message || appState.result.message
          : appState.result.message}
      </p>
      <button
        className='body__button__completed'
        onClick={() =>
          setAppState({ loading: false, amount: delegation.available })
        }
      >
        Try again
      </button>
    </>
  ) : (
    <>
      <h1 className='body__title'>Success</h1>
      <p className='body__result__txt'>
        TX HASH:{' '}
        {appState.result.transactionHash.substring(0, 10) +
          '...' +
          appState.result.transactionHash.substring(52)}
      </p>
      <button
        className='body__button__completed'
        onClick={() =>
          setAppState({ loading: false, amount: delegation.available })
        }
      >
        Done
      </button>
    </>
  )
}

export default Delegate
