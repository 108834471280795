import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { ethers } from 'ethers'

import { wrap, getSigner, getToken } from './../logic/web3'

import loader from '../loader.svg'

function Wrap() {
  const [appState, setAppState] = useState({ loading: false })
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    async function getBalance() {
      setAppState({ loading: true })
      const b = parseFloat(
        ethers.utils.formatUnits(await getSigner().getBalance(), 18),
      )
      setBalance(b)
      setAppState({ loading: false })
    }
    getBalance()
  }, [])

  async function wrapTx() {
    if (
      !document.getElementById('wrap__amount').value ||
      parseFloat(document.getElementById('wrap__amount').value) <= 0
    )
      return
    setAppState({ loading: true })
    const result = await wrap(
      document.getElementById('wrap__amount').value,
    ).catch((e) => {
      return e
    })
    setAppState({ loading: false, result })
  }

  function addToMetamask() {
    getToken() === 'SGB'
      ? window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED',
              symbol: 'wSGB',
              decimals: 18,
              image: 'https://ftso.scandinodes.com/sgb-icon.svg',
            },
          },
        })
      : window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
              symbol: 'wFLR',
              decimals: 18,
              image: 'https://ftso.scandinodes.com/flr-icon.jpeg',
            },
          },
        })
  }

  function setMax() {
    if (balance <= 0.5) return
    document.getElementById('wrap__amount').value =
      document.getElementById('wrap__amount').max
  }

  return appState.loading ? (
    <>
      <h1 className='body__title'>Wrap</h1>
      <img src={loader} alt='loading' className='body__loader' />
      <p className='body__loader__txt'>loading</p>
    </>
  ) : !appState.result ? (
    <>
      <h1 className='body__title'>Wrap</h1>
      <div className='input__container'>
        <input
          type='number'
          className='body__input'
          placeholder={
            balance > 0.5
              ? 'Amount: ' + (balance - 0.5).toFixed(3)
              : `You don't have enough ${getToken()}`
          }
          id='wrap__amount'
          min='0'
          max={balance - 0.5}
        />
        <button
          className='input__max'
          style={{
            color: balance > 0.5 ? 'var(--blue)' : 'transparent',
            cursor: balance > 0.5 ? 'pointer' : 'text',
          }}
          onClick={setMax}
        >
          MAX
        </button>
      </div>

      <button
        className='body__button'
        onClick={wrapTx}
        style={{ marginTop: '3.4rem', marginBottom: '1.6rem' }}
      >
        <span>WRAP</span>
      </button>
    </>
  ) : appState.result.code ? (
    <>
      <h1 className='body__title'>Error</h1>
      <p className='body__result__txt'>
        {appState.result.data
          ? appState.result.data.message || appState.result.message
          : appState.result.message}
      </p>
      <button
        className='body__button__completed'
        onClick={() => setAppState({ loading: false })}
      >
        Try again
      </button>
    </>
  ) : (
    <>
      <h1 className='body__title'>Success</h1>
      <p className='body__result__txt'>
        TX HASH:{' '}
        {appState.result.transactionHash.substring(0, 10) +
          '...' +
          appState.result.transactionHash.substring(52)}
      </p>
      <button className='body__button__completed' onClick={addToMetamask}>
        Add w{getToken()} to MetaMask
      </button>
      <NavLink to='/delegate' className='body__button'>
        Go to Delegate
      </NavLink>
    </>
  )
}

export default Wrap
