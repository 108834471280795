import { getClaimable, claim, getUnclaimable, getToken } from '../logic/web3'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { useTimer } from 'react-timer-hook'

import loader from '../loader.svg'

function Claim() {
  const [appState, setAppState] = useState({
    loading: true,
    claimable: 0,
    unclaimable: 0,
  })

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: getNextEpoch(),
    onExpire: () => {
      /*window.location.reload()*/
    },
  })

  function getNextEpoch() {
    let resultDate = new Date()
    resultDate.setUTCDate(
      resultDate.getUTCHours() < 8 ||
        (resultDate.getUTCHours() === 8 && resultDate.getUTCMinutes() < 40)
        ? resultDate.getUTCDate() +
            ((7 + 6 - resultDate.getUTCDay() - 1) % 7) +
            1
        : resultDate.getUTCDate() +
            ((7 + 6 - resultDate.getUTCDay() - 1) % 7) +
            1,
    )
    resultDate.setUTCHours(8)
    resultDate.setUTCMinutes(40)
    resultDate.setUTCSeconds(0)
    return resultDate
  }

  useEffect(() => {
    setAppState({ loading: true, claimable: 0 })
    async function updateAppState() {
      const claimable = await getClaimable().catch((e) => {
        return 0
      })
      const unclaimable =
        Math.floor(
          (await getUnclaimable().catch((e) => {
            return 0
          })) * 100,
        ) / 100
      setAppState({ loading: false, claimable, unclaimable })
    }
    updateAppState()
  }, [])

  async function updateAppState() {
    const claimable = await getClaimable().catch((e) => {
      return 0
    })
    const unclaimable = await getUnclaimable().catch((e) => {
      return 0
    })
    setAppState({ loading: false, claimable, unclaimable })
  }

  async function claimTx() {
    setAppState({ loading: true })
    const result = await claim().catch((e) => {
      return e
    })
    setAppState({ loading: false, result })
  }

  return appState.loading ? (
    <>
      <h1 className='body__title'>Claim rewards</h1>
      <img src={loader} alt='loading' className='body__loader' />
      <p className='body__loader__txt'>loading...</p>
    </>
  ) : !appState.result ? (
    <>
      <h1 className='body__title'>Claim rewards</h1>
      {appState.claimable > 0 ? (
        <>
          <p className='body__balance__txt' style={{ marginTop: '2.6rem' }}>
            Your claimable rewards: {parseFloat(appState.claimable).toFixed(2)}{' '}
            {getToken()}
          </p>
          <button
            className='body__button'
            onClick={claimTx}
            style={{ marginTop: '3.4rem', marginBottom: '1.6rem' }}
          >
            <span>CLAIM</span>
          </button>
        </>
      ) : appState.unclaimable > 0 ? (
        <p className='body__balance__txt' style={{ marginTop: '2.6rem' }}>
          Come back in{' '}
          <span>
            {days >= 7 ? 0 : days}d {hours}h {minutes}m {seconds}s
          </span>{' '}
          to claim{' '}
          <span>
            {appState.unclaimable} {getToken()}
          </span>
        </p>
      ) : (
        <p className='body__balance__txt' style={{ marginTop: '2.6rem' }}>
          You have no rewards to claim, delegate and come back in a week.
        </p>
      )}
    </>
  ) : appState.result.code ? (
    <>
      <h1 className='body__title'>Error</h1>
      <p className='body__result__txt'>
        {appState.result.data
          ? appState.result.data.message || appState.result.message
          : appState.result.message}
      </p>
      <button onClick={updateAppState} className='body__button__completed'>
        Try again
      </button>
    </>
  ) : (
    <>
      <h1 className='body__title'>Success</h1>
      <p className='body__result__txt'>
        TX HASH:{' '}
        {appState.result.transactionHash.substring(0, 10) +
          '...' +
          appState.result.transactionHash.substring(52)}
      </p>
      <NavLink to='/delegations' className='body__button__completed'>
        DONE
      </NavLink>
    </>
  )
}

export default Claim
