import { knownFTSO, getToken } from '../logic/web3'

function Delegation(props) {
  return (
    <div className='delegation'>
      <div className='delegation__container'>
        <p className='delegation__txt' style={{ width: '140px' }}>
          {knownFTSO[props.address.toLowerCase()] ||
            props.address.substring(0, 6) + '...' + props.address.substring(36)}
        </p>
        <div className='delegation__amount__container'>
          <p className='delegation__txt' style={{ color: 'var(--orange)' }}>
            {props.amount / 100}%
          </p>
          <p className='delegation__amount'>
            {(props.balance * (props.amount / 10_000)).toFixed(0)}{' '}
            <span>w{getToken()}</span>
          </p>
        </div>
        <button className='delegation__button' onClick={props.tx}>
          <span>UNDELEGATE</span>
        </button>
      </div>
    </div>
  )
}

export default Delegation
