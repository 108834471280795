import React, { useState, useEffect } from 'react'

import { unwrap, getWnatBalance, getToken } from './../logic/web3'

import loader from '../loader.svg'

function Unwrap() {
  const [appState, setAppState] = useState({ loading: false })
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    async function getBalance() {
      setAppState({ loading: true })
      const b = await getWnatBalance()
      setBalance(b)
      setAppState({ loading: false })
    }
    getBalance()
  }, [])

  async function unwrapTx() {
    if (!document.getElementById('unwrap__amount').value) return
    setAppState({ loading: true })
    const result = await unwrap(
      document.getElementById('unwrap__amount').value,
    ).catch((e) => {
      return e
    })
    const b = await getWnatBalance()
    setBalance(b)
    setAppState({ loading: false, result })
  }

  function setMax() {
    if (balance <= 0) return
    document.getElementById('unwrap__amount').value =
      document.getElementById('unwrap__amount').max
  }

  return appState.loading ? (
    <>
      <h1 className='body__title'>Unwrap</h1>
      <img src={loader} alt='loading' className='body__loader' />
      <p className='body__loader__txt'>loading...</p>
    </>
  ) : !appState.result ? (
    <>
      <h1 className='body__title'>Unwrap</h1>
      <div className='input__container'>
        <input
          type='number'
          className='body__input'
          placeholder={
            balance > 0
              ? 'Available: ' + balance
              : `You don't have any w${getToken()}`
          }
          id='unwrap__amount'
          min='0'
          max={balance}
        />
        <button
          className='input__max'
          style={{
            color: balance > 0 ? 'var(--blue)' : 'transparent',
            cursor: balance > 0 ? 'pointer' : 'text',
          }}
          onClick={setMax}
        >
          MAX
        </button>
      </div>
      <button
        className='body__button'
        onClick={unwrapTx}
        style={{ marginTop: '3.4rem', marginBottom: '1.6rem' }}
      >
        <span>UNWRAP</span>
      </button>
    </>
  ) : appState.result.code ? (
    <>
      <h1 className='body__title'>Error</h1>
      <p className='body__result__txt'>
        {appState.result.data
          ? appState.result.data.message || appState.result.message
          : appState.result.message}
      </p>
      <button
        className='body__button__completed'
        onClick={() => setAppState({ loading: false })}
      >
        Try again
      </button>
    </>
  ) : (
    <>
      <h1 className='body__title'>Success</h1>
      <p className='body__result__txt'>
        TX HASH:{' '}
        {appState.result.transactionHash.substring(0, 10) +
          '...' +
          appState.result.transactionHash.substring(52)}
      </p>
      <button
        className='body__button__completed'
        onClick={() => setAppState({ loading: false })}
      >
        Done
      </button>
    </>
  )
}

export default Unwrap
