import React, { useState, useEffect } from 'react'
import { getProvider } from '../../logic/web3'

import './Footer.css'

import twitter from './twitter.svg'
import scandinodes from './scandinodes.svg'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

function Footer() {
  const [block, setBlock] = useState('Disconnected')

  useEffect(() => {
    async function updateBlock() {
      while (typeof getProvider() === 'undefined') await delay(300)

      while (true) {
        const n = await getProvider().getBlockNumber()

        setBlock(n)
        await delay(20000)
      }
    }

    updateBlock()
  }, [])

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer_text'>
          <div
            className={
              'footer__status ' +
              (block === 'Disconnected' ? 'disconnected' : '')
            }
          ></div>{' '}
          <p>{block}</p>
        </div>
        <a
          href='https://twitter.com/scandinodesFTSO'
          target='_blank'
          rel='noreferrer'
        >
          <img src={twitter} alt='Twitter Logo' className='footer__twitter' />
        </a>
        <a href='https://ftso.scandinodes.com' target='_blank' rel='noreferrer'>
          <img
            src={scandinodes}
            alt='Scandinodes Logo'
            className='footer__logo'
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
