import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { getDelegations, undelegate, getWnatBalance } from '../logic/web3'

import Delegation from './Delegation.js'
import loader from '../loader.svg'

function Delegations() {
  const [appState, setAppState] = useState({ loading: true, delegations: [] })
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    async function getAppState() {
      setAppState({ loading: true, delegations: [] })
      const delegations = await getDelegations()
      const b = await getWnatBalance()
      setBalance(b)
      setAppState({ loading: false, delegations })
    }
    getAppState()
  }, [])

  async function updateAppState() {
    setAppState({ loading: true, delegations: [] })
    const delegations = await getDelegations()
    setAppState({ loading: false, delegations })
  }

  async function undelegateTx(from) {
    setAppState({ loading: true, delegations: [] })
    const result = await undelegate(from).catch((e) => {
      return e
    })

    setAppState({ loading: false, result })
  }

  return appState.loading ? (
    <>
      <h1 className='body__title'>Your delegations</h1>
      <img src={loader} alt='loading' className='body__loader' />
      <p className='body__loader__txt'>loading...</p>
    </>
  ) : !appState.result ? (
    <>
      <h1 className='body__title' style={{ marginBottom: '2.5rem' }}>
        Your delegations
      </h1>
      {appState.delegations.length > 0 ? (
        <>
          {appState.delegations.map((e, i) => (
            <Delegation
              key={i}
              address={e.address}
              amount={e.amount}
              balance={balance}
              tx={() => {
                undelegateTx(e.address)
              }}
            />
          ))}
          <NavLink
            className='body__button'
            to='/claim'
            style={{ marginTop: '2.8rem' }}
          >
            <span>CLAIM REWARDS</span>
          </NavLink>
        </>
      ) : (
        <>
          <p className='body__balance__txt' style={{ paddingTop: '2rem' }}>
            You don't have any delegation
          </p>
          <NavLink
            className='body__button__completed'
            to='/delegate'
            style={{ marginTop: '1rem' }}
          >
            Delegate to ScandiNodes
          </NavLink>
        </>
      )}
    </>
  ) : appState.result.code ? (
    <>
      <h1 className='body__title'>Error</h1>
      <p className='body__result__txt'>
        {appState.result.data
          ? appState.result.data.message || appState.result.message
          : appState.result.message}
      </p>
      <button className='body__button__completed' onClick={updateAppState}>
        Try again
      </button>
    </>
  ) : (
    <>
      <h1 className='body__title'>Success</h1>
      <p className='body__result__txt'>
        TX HASH:{' '}
        {appState.result.transactionHash.substring(0, 10) +
          '...' +
          appState.result.transactionHash.substring(52)}
      </p>
      <button className='body__button__completed' onClick={updateAppState}>
        Done
      </button>
    </>
  )
}

export default Delegations
