import './Mobile.css'
import scandinodes from './scandinodes.svg'

function Mobile() {
  return (
    <div className='mobile'>
      <div className='mobile__logo__container'>
        <img src={scandinodes} alt='Scandinodes' className='mobile__logo' />
      </div>
      <div className='mobile__container'>
        <h1 className='mobile__title'>
          Please open this page on your computer.
        </h1>
        <p className='mobile__txt'>
          For the moment our app is not available for mobile and tablet devices
        </p>
        <a className='mobile__button' href='http://ftso.scandinodes.com'>
          VISIT OUR WEBSITE
        </a>
      </div>
    </div>
  )
}

export default Mobile
